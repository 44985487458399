import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Footer = () => (

<section className="contact-section bg-black">
    <div className="container">
        <div className="row">
        <div className="col-md-2 mb-3 mb-md-0">
        </div>
        <div className="col-md-8 mb-3 mb-md-0">
            <div className="card py-4 h-100">
                <div className="card-body text-center">
                    <FontAwesomeIcon icon={faEnvelope} className="footer-envelope" />  
                    <h4>SAY HELLO, WE'LL HELP YOU<br />GROW YOUR BRAND</h4>
                    <hr className="my-4" />
                    <div className="small text-black-50">
                        <a href="mailto:hello@cannab.agency">hello@cannab.agency</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-2 mb-3 mb-md-0">
        </div>
        </div>

        <div className="social d-flex justify-content-center">
            <a href="https://twitter.com/AgencyCannab" >
               <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://www.facebook.com/cannabagency/" className="mx-2">
                <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/cannabagency/" className="mx-2">
                <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://lu.linkedin.com/organization-guest/company/cannab-agency" className="mx-2">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
        </div>
        <footer>
            <p>Copyright &copy; Cannab Agency {new Date().getFullYear()} </p>
        </footer> 
    </div>
</section>

)
  
  export default Footer