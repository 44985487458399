import React from 'react'
import { Link } from 'gatsby'
import { Navbar, Nav, Container } from 'react-bootstrap'
import Scrollspy from 'react-scrollspy'

import logo from "../images/fox-logo.svg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
}

export default function navbar() {

    return (
        <Navbar expand="lg" fixed="top" id="menu-wrap" className="menu-back cbp-af-header" variant="dark">
            <Container>
            <Navbar.Brand href="/#home" className="logo"><img src={logo} alt="Cannab Logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
                <Scrollspy items={ ['about', 'principles', 'principals', 'services', 'connect'] } currentClassName="active" className="navbar-nav ml-auto">
                    <Nav.Item as="li">
                        <Link className="nav-link about" to="/#about">About Us</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link principles" to="/#principles">Our Principles</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link principals" to="/#principals">Principals &amp; Producers</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link specialties" to="/#services">Our Specialties</Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link className="nav-link" to="/bluntly" activeClassName="active">Bluntly</Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="socialIcons">
                        <a href="https://twitter.com/AgencyCannab" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                        <a href="https://www.facebook.com/cannabagency/" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a href="https://www.instagram.com/cannabagency/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href="https://lu.linkedin.com/organization-guest/company/cannab-agency"><FontAwesomeIcon icon={faLinkedin} /></a>
                    </Nav.Item>
                </Scrollspy> 
            </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}