import React from "react"

import "../theme/css/bootstrap.css"
import "../theme/css/grayscale.min.css"

import Navbar from './navbar'

const Header = () => (
  <Navbar />
)
  
export default Header